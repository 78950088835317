html {
  box-sizing: border-box;
  &.SideBarActive{
	overflow: hidden;
	}
}

body {
  display: unset;
  &.SideBarActive{
	overflow: hidden;
	position: relative;
	.header-wrapper{
		&::before{
			background: rgba(0, 0, 0, 0.40);
			width: 100%;
			height: 100%;
			content: '';
			left: 0;
			top: 0;
			position: absolute;
			z-index: 3;
			@media (max-width: 767px) {
				background: transparent;
			}
		}
	}
  }
}


.section-image{
	display: none;
	img{
		width: 100%;
	}
	@media (max-width: 767px) {
		display: block;
	}
}
.header-wrapper {
	position: relative;
	background-color: #eee; /* Fallback color */
	background-image: url('https://cdn.pixabay.com/photo/2023/01/01/13/47/street-7690119_1280.jpg');
	background-size: cover;
	background-position: center;
	width: 100%;
	height: calc(var(--vh-static, 1vh) * 100);
	// overflow: hidden;
	&.not-in-viewport{
		.chat-assistant-btn{
			transition: ease 0.5s all;

			@media (max-width: 767px) {
				left: auto;
				right: 20px;
				transform: rotate(0Deg);
				top: 45px;
				width: auto;
				background-color: transparent;
				box-shadow: none;
				visibility: visible;
				position: fixed;
				display: flex;
				opacity: 1;
			}
			.assistant-icon{
				@media (max-width: 767px) {
					width: 57px;
					height: 57px;
					border-top-right-radius: 6px;
				}
				img{
					@media (max-width: 767px) {
						width: 32px;
						height: auto;
					}
				}
				&::after{
					@media (max-width: 767px) {
						display: none;
					}
				}
			}
			span{
				@media (max-width: 767px) {
					display: none;
				}
			}
		}
	}
	.video-box{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		// add a background gradient for overlay from left to right to make the text more redable
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(90deg,rgba(0,0,0,.4),rgba(0,0,0,.1));
		}
		@media (max-width: 767px) {
			&::before{
				background-color: #000000;
				position: absolute;
				height: 100%;
				width: 100vw;
				left: 0;
				top: 0;
				content: '';
				opacity: 0.2;
				z-index: 1;
			}
		}
	
		video{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.navbar {
		z-index: 2;
		position: absolute;
		// height: 128px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0 5.8% 0 5.8%;
		width: calc(100% - 11.5%); // 92+92=184
		padding: 27px 0 39px;
		@media (max-width: 1023px) {
			width: calc(100% - 48px); // 24+24
			margin: 0 24px 0 24px;
			height: 80px;
		}
		.nav-btns-desktop {
			display: flex;

			div {
				img {
					width: clamp(30px, 3rem, 56px);
					height: clamp(30px, 3rem, 56px);
				}
			}
			div:not(:last-child) {
				margin-right: 0.75rem;
			}
			div:last-child img {
				width: clamp(60px, 5rem, 80px);
			}
			@media (max-width: 1366px) {
				img {
					width: 40px;
					height: 40px;
				}
			}
			@media (max-width: 1023px) {
				display: none;
			}
		}
		.nav-btns-mobile {
			display: flex;
			justify-content: space-between;
			div {
				@media (max-width: 991px) {
					margin-right: 10px;
					&:last-child{
						margin-right: 0px;
					}
				}
				img {
					width: 24px;
					height: 24px;
				}
			}

			@media (min-width: 1023px) {
				display: none;
			}
		}
		.nav-menu-wrapper{
			display: flex;
			align-items: center;
			.logo {
				margin-right: 75px;
				@media (max-width: 1199px) {
					margin-right: 60px;
				}
				img {
					width: 100%;
				}
				@media (max-width: 1023px) {
					margin-right: 24px;
					img {
						width: 113px;
					}
				}
			}
			.nav-links {
				@media (max-width: 1023px) {
					display: none;
				}
				.nav-links-wrapper {
					display: flex;
					li {
						color: white;
						font-weight: 700;
						font-size: 19.2px;
						line-height: 28.8px;
						margin-right: 36px;
						font-size: clamp(12px, 1rem, 22px);
						@media (max-width: 1199px) {
							font-size: 10px;
							margin-right: 15px;
						}
					}
				}
			}
		}
	}
	.content-section {
		z-index: 2;
		position: relative;
		color: #fff;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 92px;
		width: 50%;

		@media (max-width: 1023px) {
			margin: 0 24px;
			width: calc(100% - 300px); // 24+24
		}
		@media (max-width: 767px) {
			margin: 0 24px;
			width: calc(100% - 48px); // 24+24
		}
	
		.title {
			font-weight: 700;
			font-size:  5.4rem;
			line-height: 1;
			margin-bottom: 30px;
			@media (max-width: 1439px) {
				font-size:  6rem;
			}
			@media (max-width: 1023px) {
				font-size: 40px;
				line-height: 48px;
				margin-bottom: 20px;
			}
			@media (max-width: 767px) {
				margin-bottom: 16px;
			}
		}
	
		.description {
			font-weight: 400;
			font-size: 19.2px;
			line-height: 27.6px;
			strong{
				font-weight: 700;
				@media (max-width: 767px) {
					font-weight: 400;
				}
			}
			@media (max-width: 767px) {
				margin-top: 0;
				font-size: 16px;
				line-height: 24px;
			}
		}
		.chat-search-field {
			position: relative;
			margin-top: 28.8px;
			width: clamp(321px, 57%, 40rem);
			@media (max-width: 767px) {
				margin-top: 16px;
				width: 100%;
			}
			.chat-field-input {
				font-family: 'Abu Dhabi Basis';
				width: 100%;
				padding: 14.4px 24px;
				border-radius: 120px;
				font-weight: 700;
				color: #000;
				line-height: 19.2px;
				height: 68px;
				font-size: 17px;
				&::placeholder{
					color: #000;
				}
				@media (max-width: 767px) {
					height: 67px;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
				}
			}
			.chat-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				width: 58px;
				height: 58px;
				border-radius: 50%;
				background-color: #FF8707;
				top: 5px;
				right: 5px;
				cursor: pointer;
				@media (max-width: 767px) {
					width: 57px;
					height: 57px;
					right: 3px;
				}
				img {
					width: 27px;
					height: 26px;
					@media (max-width: 767px) {
						width: 32px;
						height: 100%;
					}
				}
			}
		}
	}
	.supergraphicImg {
		width: auto;
		height: calc(var(--vh-static, 1vh) * 83.1);
		bottom: 16px;
		right: -150px;
		position: absolute;

		@media (max-width: 1023px) {
		right: -250px;
		bottom: -200px;
		}

		@media (max-width: 400px) {
		right: -150px;
		bottom: -200px;

		}

		img {
		width: auto;
		height: 100%;
		}
	}
	.chat-assistant-btn {
		z-index: 2;
		position: absolute;
		display: flex;
		top: 50%;
		right: -190px; // 223 - (12+12)
		width: 223px;
		height: 66px;
		box-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.3);
		color: #fff;
		cursor: pointer;
		padding: 0 16px;
		align-items: center;
		background: #003451;
		justify-content: space-around;
		border-radius: 20px 20px 0 0;
		transform: translate(-50%, -50%) rotate(-90deg); 
		@media (max-width: 767px) {
			background: transparent;
			opacity: 0;
			visibility: hidden;
			bottom: 0;
			left: auto;
			top: unset;
			padding: 0;
			right: auto;
			width: 204px;
			height: 50px;
			transform: translate(0%, 0%) rotate(0); 
			
		}

		.assistant-icon {
			position: relative;
			display: flex;
			width: 31px;
			height: 31px;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			background-color: orange;
			@media (max-width: 767px) {
				width: 26px;
				height: 26px;
			}
			&:after{
				content: '';
				position: absolute;
				top: -5px;
				left: -5px;
				right: -5px;
				bottom: -5px;
				width: 41px;
				height: 41px;
				border-radius: 50px;
				border: 5px solid rgba(255, 255, 255, 0.20);
				@media (max-width: 767px) {
					width: 36px;
					height: 36px;
				}
			}

			img {
				width: 15.5px;
				height: 14px;
				transform: rotate(90deg);
				@media (max-width: 767px) {
					transform: rotate(0deg);
				}
			}
		}
		
		span {
			font-size: clamp(14px, 14px, 14px);
			font-weight: 700;
		}
	}
	.chat-bot-app {
		position: fixed;
		top: 0;
		right: 0;
		// width: 0;
		z-index: 3;
		max-width: 600px;
		height: 100%;
		background-color: #003451;
		transition: all 0.5s ease;
		transform: translateX(1080px);
		@media (max-width: 767px) {
			width: 100%;
			max-width: 100%;
			transform: translateX(0);
			transform: translateY(115%);
		}
		&.active{
			z-index: 3;
			width: 600px;
			transform: translateX(0);
			@media (max-width: 767px) {
				position: fixed;
                width: 100%;
				transform: translateX(0);
				transform: translateY(0);
            }
		}
		
		.chat-bot-app-wrapper {
			width: 100%;
			height: 100%;
			.screen-cover{
				position: relative;
				height: 100%;
				.screen-close-btn{
					position: absolute;
					top: 50%;
					left: -40px;
					transform: translateX(-50%);
					@media (max-width: 767px) {
						display: none;
					}
					a{
						display: flex;
						width: 48px;
						height: 48px;
						padding: 16px;
						align-items: center;
						border-radius: 100px;
						justify-content: center;
						background-color: #fff;
					}
				}
				.chatbot-view { 
					.wrapper {
						.screen-intro{
							.screen-bg{
								bottom: 0;
								height: auto;
								@media (max-height: 750px) {
									height: 80% ;
									img{
										object-fit: fill;
									}
								}
								@media (max-width: 767px) {
									height: auto ;
									img{
										object-fit: cover;
									}
								}
							}
							.screen-fg{
								padding: 0;
								.header{
									background-color: #003451;
									padding: 26px 0;
									text-align: center;
									// box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.30);
									a{
										display: none;
									}
									@media (max-width: 767px) {
										position: relative;
										padding: 24px 0;
										padding-left: 16px;
										a{
											display: block;
											position: absolute;
											top: 50%;
    										right: 20px;
											transform: translateY(-50%);
										}
									}
									p{
										color: #ffffff;
										font-size: 18px;
										font-weight: 700;
										line-height: 21.96px;
										@media (max-width: 767px) {
											font-size: 16px;
											line-height: 24px;
										}
									}
								}
								.content-wrapper{
									height: 100%;
									padding: 0 40px;
									margin-top: 40px;
									display: flex;
									flex-direction: column;
									justify-content: space-between;
									@media (max-width: 767px) {
										padding: 0 16px;
									}
									.topic-text{
										padding-bottom: 80px;
										@media (max-width: 767px) {
											padding-bottom: 32px;
										}
										@media (max-height: 900px) {
											padding-bottom: 40px;
										}
										h2{
											font-size: 64px;
											color: #ffffff;
											line-height: 72px;
											font-weight: 700;
											@media (max-width: 1440px) {
												font-size: 48px;
												line-height: 56px;
											}
											@media (max-height: 900px) {
												font-size: 40px;
												line-height: 48px;
											}
											@media (max-width: 991px) {
												font-size: 42px;
												line-height: 50px;
											}
											@media (max-width: 767px) {
												font-size: 40px;
												line-height: 48px;
											}
										}
									}
									.topic-content{
										padding: 0;
										.help-txt{
											p{
												font-size: 24px;
												color: #ffffff;
												font-weight: 400;
												line-height: 31.2px;
												@media (max-width: 767px) {
													display: none;
												}
											}
										}
										.chat-topics{
											margin-top: 32px;
											max-height: 272px;
											@media (max-width: 767px) {
												max-height: 276px;
												overflow: scroll !important;
												width: calc(100% + 10px);
												padding-right: 10px;
												
											}
											@media (max-height: 750px) {
												max-height: 180px;
											}
											// @media (max-height: 600px) {
											// 	max-height: 88px;
											// }
										}
									}
								}
								.footer{
									width: 100%;
									padding: 0 40px;
									margin-top: 80px;
									@media (max-width: 767px) {
										padding: 0 16px;
										margin-top: 20px;
        								padding-bottom: 16px;
									}
									@media (max-height: 900px) {
										margin-top: 40px;
									}
									.footer-words-count{
										display: flex;
										margin-top: 22px;
										justify-content: space-between;
										@media (max-width: 767px) {
											margin-top: 16px;
											display: none;
										}
										p,span{
											font-size: 12px;
											color: #898C8D;
											font-weight: 400;
											line-height: 14.64px;
											@media (max-width: 767px) {
												font-size: 12px;
												line-height: 13px;
											}
										}
									}
								}
							}
						}
						.screen-chat{
							position: relative;
							padding: 0;
            				height: 100%;
							padding-bottom: 48px;
							@media (max-width: 767px) {
								padding-bottom: 0;
							}
							.screen-bg {
								position: absolute;
								z-index: 0;
								width: 100%;
								height: 100%;
								img {
									width: 100%;
									height: 100%;
								}
							}
							.screen-sg{
								.chat-screen-wrapper{
									display: flex;
									height: 100vh;
									flex-direction: column;
									@media (max-width: 767px) {
										height: 100%;
									}
									.header{
										padding: 26px 40px;
										text-align: center;
										background: #003451;
										box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.30);
										@media (max-width: 767px) {
											padding: 24px 0;
											padding-left: 16px;
										}
										.js-cta-end{
											display: none;
											@media (max-width: 767px) {
												display: block;
											}
										}
										p{
											color: #ffffff;
											font-size: 18px;
											font-weight: 700;
											line-height: 21.96px;
											@media (max-width: 767px) {
												font-size: 16px;
												line-height: 24px;
											}
										}
									}
									.content-wrapper{
										height: auto;
										overflow: auto;
										// padding-top: 35px;
										@media (max-width: 767px) {
											height: 100% !important;
										}
										.chat-list{
											padding: 0px;
										}
									}
									.activity-slider-wrapper{
										width: 100%;
										// overflow: hidden;
										padding: 0 0px 20px 40px;
										padding-bottom: 60px;
										@media (max-width: 767px) {
											width: calc(100% + 16px);
											padding: 0 ;
										}
										.activity-slider{
											width: 100%;
											// @media (max-width: 767px) {
											// 	width: calc(100% + 120px);
											// }
											.slider-box{
												// max-width: 600px;
												overflow: visible;
												@media (max-width: 767px) {
													padding:  0 16px;
													overflow: scroll;
													display: flex;
													padding-bottom: 10px !important;
												}
												.slick-list{
													width: 100%;
													overflow: visible;
													.slick-track{
														display: flex;
														justify-content: flex-start;
													}
												}
												.slider-item{
													border: 3px solid rgba(255, 255, 255, 0.10);
													width: 260px !important;
													margin-right: 16px;
													border-radius: 16px;
													background: #003451;
													box-shadow: 0px 5.217px 83.478px 0px rgba(41, 38, 47, 0.10);
													@media (max-width: 767px) {
														width: 200px !important;
													}
													.item-img{
														position: relative;
														height: 136px;
														overflow: hidden;
														img{
															max-width: 100%;
															width: 100%;
															height: 100%;
															border-radius: 16px 16px 0 0;
														}
														.label{
															position: absolute;
															top: 14px;
															left: 14px;
															span{
																display: flex;
																font-size: 12px;
																font-weight: 700;
																color: #000000;
																padding: 5px 10px;
																line-height: 20px;
																letter-spacing: 0.65px;
																text-transform: uppercase;
																background-color: #ffffff;
																@media (max-width: 767px) {
																	font-size: 12px;
																	line-height: 16px;
																	letter-spacing: 0.5px;
																}
															}
														}
													}
													.item-content{
														padding: 18px 18px 20px;
														@media (max-width: 767px) {
															padding: 14px 15px;
														}
														h2{
															font-size: 24px;
															font-weight: 700;
															color: #ffffff;
															line-height: 31px;
															margin-bottom: 13px;
															min-height: 64px;
															display: -webkit-box;          
															-webkit-box-orient: vertical; 
															-webkit-line-clamp: 2;        
															overflow: hidden;              
															text-overflow: ellipsis;     
															max-height: 3em;              
															line-height: 31px;   
															@media (max-width: 767px) {
																font-size: 18px;
																margin-bottom: 9px;
																line-height: 23px;
															}
														}
														p{
															font-size: 14px;
															font-weight: 400;
															color: #898C8D;
															line-height: 18px;
															white-space: nowrap;       /* Prevents the text from wrapping */
															overflow: hidden;          /* Hides overflowing text */
															text-overflow: ellipsis;   /* Adds an ellipsis at the end of the line */
															width: 100%;               /* Set the width of the container */
															display: block; 
															@media (max-width: 767px) {
																width: 170px;
																font-size: 12px;
																line-height: 15px;
															}
														}
													}
												}
												.slick-arrow{
													position: absolute;
													bottom: -60px;
													right: 12px;
													text-indent: -9999px;
													width: 46px;
													height: 46px;
													border-radius: 50px;
													border: 1px solid #ffffff;
													background-color: #fff;
													box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
													cursor: pointer;
													@media (max-width: 767px) {
														width: 30px;
														height: 30px;
														right: 20px;
														display: none !important;
													}
													&:before{
														content: '';
														position: absolute;
														top: 50%;
														left: 50%;
														width: 24px;
														height: 24px;
														transform: translate(-50%, -50%);
														background-image: url('../../assets/img/arrow-black.svg');
														background-repeat: no-repeat;
														background-size: cover;
														background-position: center center;
													}
													&.slick-prev{
														right: 66px;
														@media (max-width: 767px) {
															left: 0px;
															display: none;
														}
														&:before {
															transform: translate(-50%, -50%) rotate(180deg); // Rotate the arrow
															background-image: url('../../assets/img/arrow-black.svg');
														  }
													}
													&.slick-disabled{
														opacity: 0.2;
														pointer-events: none;
													}
												}
											}
										}
									}
									.footer{
										// left: 0;
										bottom: 0;
										width: 100%;
										padding: 5px 40px 0 40px;
										@media (max-width: 767px) {
											position: absolute;
											background-color: #003C57;
											padding: 5px 16px 0px 16px;
										}
										.footer-words-count{
											display: flex;
											margin-top: 22px;
											justify-content: space-between;
											@media (max-width: 767px) {
												margin-top: 16px;
											}
											p,
											.char-count{
												font-size: 12px;
												color: #898C8D;
												font-weight: 400;
												line-height: 14.64px;
												@media (max-width: 767px) {
													font-size: 9px;
													line-height: 13px;
												}
												&.limit-exceeded{
													color: red;
												}
											}
											.char-count{
												@media (max-width: 767px) {
													display: none;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.js-chat-header{
	height: 75px;
}
.js-chat-footer{
	height: 153px;
	@media (max-width: 767px) {
		height: 103px;
	}
}
@media (max-width: 767px) {
	.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
	.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
	.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar{
		background: #ffffff;
	}
	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		width: 3px;
		background: #ffffff;
	}
	.mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical{
		opacity: 1;
		width: 1px;
		height: 100%;
	}
	.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail{
		border-radius: 24px;
		background: rgba(247,247,247,0.5);
	}
	.mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical, 
	.mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical{
		top: -10px;
		right: -10px;
	}
}
.ctaWrapper{
	position: fixed;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	border: 1px solid #0D746D;
	border-radius: 40px;
	padding: 14px 16px;
	background-color: #009086;
	display: flex;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	z-index: 3;
	pointer-events: none;
	@media (max-width: 767px) {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}
	a{
		margin-right: 32px;
		&:last-child{
			position: relative;
			margin-right: 0;
			&::before{
				width: 1px;
				height: 24px;
				left: -16px;
				top: 0;
				background-color: #0D746D;
				content: '';
				position: absolute;
			}
		}
	}
}