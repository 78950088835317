.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto;
}

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr;
}

.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
}

.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}

.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools, .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px;
}

.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
}

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonUp {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  height: 100%;
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft, .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}

.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

.mCSB_scrollTools, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)";
}

.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools, .mCustomScrollBox:hover > .mCSB_scrollTools, .mCustomScrollBox:hover ~ .mCSB_scrollTools, .mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag, .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)";
}

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp {
  background-image: url(mCSB_buttons.png);
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
}

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
}

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
}

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
}

.mCSB_scrollTools .mCSB_buttonDown:hover, .mCSB_scrollTools .mCSB_buttonLeft:hover, .mCSB_scrollTools .mCSB_buttonRight:hover, .mCSB_scrollTools .mCSB_buttonUp:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_buttonDown:active, .mCSB_scrollTools .mCSB_buttonLeft:active, .mCSB_scrollTools .mCSB_buttonRight:active, .mCSB_scrollTools .mCSB_buttonUp:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0;
}

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px;
}

.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
}

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, .mCS-rounded.mCSB_scrollTools .mCSB_dragger {
  height: 14px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0;
}

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0;
}

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=);
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px;
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px;
}

.mCS-3d-dark.mCSB_scrollTools, .mCS-3d.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer, .mCS-3d-thick.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
  right: 1px;
}

.mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCS-3d-thick.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal, .mCS-3d-thick.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  height: 12px;
  width: auto;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #777;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0;
}

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}

.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  left: 0;
  right: auto;
}

.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px;
}

.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
}

.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0;
}

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px;
}

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: -21px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

@font-face {
  font-family: "Abu Dhabi Basis";
  src: url("../fonts/BasicAbuDhabi-Regular.woff2") format("woff2"), url("../fonts/BasicAbuDhabi-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Abu Dhabi Basis";
  src: url("../fonts/BasicAbuDhabi-Bold.woff2") format("woff2"), url("../fonts/BasicAbuDhabi-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
html {
  margin: 0;
  font-family: "Abu Dhabi Basis";
  font-size: 0.833333333vw;
  font-weight: 400;
  overflow: hidden;
  color: #000000;
}
@media (max-width: 767px) {
  html {
    overflow: auto;
    font-size: 3.833333333vw;
  }
}

body {
  overflow: hidden;
  margin: 0;
  font-family: "Abu Dhabi Basis";
  font-size: 0.833333333vw;
  font-weight: 400;
  color: #000000;
}
@media (max-width: 767px) {
  body {
    overflow: auto;
    font-size: 3.833333333vw;
  }
}

h1 {
  font-size: 48px;
  line-height: 1.25;
  font-weight: 700;
}

p {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 700;
}

.para-large {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;
}
.para-large strong {
  font-weight: 700;
}

.para-small {
  font-size: 8px;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
}
input:focus-visible {
  outline: none;
}

@media (min-width: 991px) {
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .screen-cover {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .screen-cover .mobile-frame {
    width: 100%;
    margin-left: auto;
    height: 100%;
  }
  .chatbot-view {
    position: relative;
  }
  .chatbot-view:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 20;
    pointer-events: none;
  }
}
.chatbot-view {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
}
@media (max-width: 990px) {
  .chatbot-view {
    height: 100vh;
    border-radius: 0;
    max-width: none;
  }
}
.chatbot-view .wrapper {
  position: relative;
  height: 100%;
}
.chatbot-view .wrapper .screen-close-btn {
  position: absolute;
  left: -50px;
  top: 50px;
}
.chatbot-view .wrapper .screen-intro {
  position: relative;
  height: 100%;
}
.chatbot-view .wrapper .screen-intro .screen-bg {
  position: absolute;
  z-index: 0;
  width: 100%;
}
.chatbot-view .wrapper .screen-intro .screen-bg img {
  width: 100%;
  height: 100%;
}
.chatbot-view .wrapper .screen-intro .screen-fg {
  position: relative;
  padding: 30px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 990px) {
  .chatbot-view .wrapper .screen-intro .screen-fg {
    padding: 40px 0 10px;
    max-width: 375px;
    margin: 0 auto;
  }
}
@media (max-width: 990px) {
  .chatbot-view .wrapper .screen-intro .screen-fg {
    max-width: 100%;
  }
}
.chatbot-view .wrapper .screen-intro .screen-fg .header {
  padding: 0 16px;
  flex: 0 0 auto;
}
.chatbot-view .wrapper .screen-intro .screen-fg .header h1 {
  font-size: 42px;
  line-height: 1.2;
}
.chatbot-view .wrapper .screen-intro .screen-fg .header h1 i {
  white-space: nowrap;
}
.chatbot-view .wrapper .screen-intro .screen-fg .header h1 span {
  color: #FF8707;
}
.chatbot-view .wrapper .screen-intro .screen-fg .topic-content {
  padding: 35px 16px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-intro .screen-fg .topic-content {
    flex: 1 1 auto;
    overflow: visible;
  }
}
.chatbot-view .wrapper .screen-intro .screen-fg .topic-content .chat-topics {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}
.chatbot-view .wrapper .screen-intro .screen-fg .topic-content .chat-topics .topic-wrapper a {
  display: flex;
  align-items: center;
  padding: 8px;
  text-decoration: none;
  margin-bottom: 4px;
  color: #000;
  font-weight: 400;
  line-height: 24px; /* 150% */
  background-color: #fff;
  backdrop-filter: blur(16px);
  border-radius: 24px 24px 4px 24px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-intro .screen-fg .topic-content .chat-topics .topic-wrapper a {
    padding: 8px;
  }
}
.chatbot-view .wrapper .screen-intro .screen-fg .topic-content .chat-topics .topic-wrapper a img {
  max-width: 72px;
  margin-right: 16px;
  border-radius: 16px;
  height: 72px;
}
.chatbot-view .wrapper .screen-intro .screen-fg .topic-content .chat-topics .topic-wrapper a:last-child {
  margin-bottom: 0;
}
.chatbot-view .wrapper .screen-intro .screen-fg .topic-content .chat-topics .topic-wrapper a p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-intro .screen-fg .topic-content .chat-topics .topic-wrapper a p {
    font-size: 16px;
  }
}
.chatbot-view .wrapper .screen-intro .screen-fg .footer {
  padding: 6px 16px;
  flex: 0 0 auto;
}
.chatbot-view .wrapper .screen-intro .screen-fg .footer .cta-start {
  background-color: #fff;
  border-radius: 76px;
}
.chatbot-view .wrapper .screen-intro .screen-fg .footer .cta-start a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 4px 0 24px;
  text-decoration: none;
  color: #000000;
  cursor: text;
  border: 4px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.2);
  border-radius: 76px;
}
.chatbot-view .wrapper .screen-intro .screen-fg .footer .cta-start a span.text {
  font-size: 16px;
  display: block;
  position: relative;
  top: -1px;
}
.chatbot-view .wrapper .screen-chat {
  display: none;
  padding-top: 120px;
  height: 100%;
}
.chatbot-view .wrapper .screen-chat .screen-sg {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chatbot-view .wrapper .screen-chat .screen-sg .header {
  flex: 0 0 auto;
  padding: 15px 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.06);
}
.chatbot-view .wrapper .screen-chat .screen-sg .header .para-large {
  flex: 0 0 92%;
  max-width: 92%;
  margin-left: 4px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .header .para-large {
    flex: 0 0 85%;
    max-width: 85%;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-member-wrapper {
  display: flex;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-member-wrapper {
    margin-bottom: 32px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-member-wrapper .chat-member {
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  align-items: center;
  border-radius: 50px;
  background: #FF8707;
  justify-content: center;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-member-wrapper .chat-member {
    width: 32px;
    height: 32px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-member-wrapper .chat-member img {
  height: 18px;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-member-wrapper .chat-member:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 5px solid rgba(255, 255, 255, 0.2);
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-member-wrapper .chat-member:after {
    width: 42px;
    height: 42px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-member-wrapper .default-text {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-member-wrapper .default-text {
    font-size: 18px;
    line-height: 23px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list {
  padding: 0 16px;
  flex: 1 1 auto;
  overflow: hidden;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .chat-wrapper {
  height: 100%;
  overflow-x: hidden;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .chat-wrapper {
    max-height: calc(100svh - 101px - 75px);
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .suggestions-list {
  padding: 0 40px;
  padding-top: 35px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .suggestions-list {
    padding: 0 16px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .suggestions-list .list-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .suggestions-list .list-wrapper {
    margin-bottom: 40px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .suggestions-list .list-wrapper a {
  color: #fff;
  padding: 12px 16px;
  border-radius: 12px;
  margin: 0 0 6px 6px;
  background: #003451;
  text-decoration: none;
  border: 3px solid rgba(255, 255, 255, 0.1);
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .suggestions-list .list-wrapper a p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .suggestions-list .list-wrapper a p {
    font-size: 16px;
    line-height: 24px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper {
  margin-top: 40px;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper.js-msg-list {
  padding-right: 40px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper.js-msg-list {
    padding-right: 16px;
  }
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper {
    padding-right: 0;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper li {
  margin-bottom: 24px;
  max-width: fit-content;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper li {
    margin-bottom: 16px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .user-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 0;
  color: #000000;
  margin-left: auto;
  padding: 16px 20px;
  width: calc(100% - 16px);
  background-color: #ffffff;
  border-radius: 24px 24px 0px 24px;
  word-break: break-word;
  overflow-wrap: break-word;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .user-text {
    margin-bottom: 16px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text {
  background-color: #003451;
  padding: 12px;
  border-radius: 0px 12px 12px 12px;
  margin-bottom: 24px;
  width: max-content;
  margin-left: 100px;
  min-height: 53px;
  position: relative;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text {
    margin-bottom: 16px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text.empty {
  background-color: transparent;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text::before {
  border-radius: 50%;
  left: -60px;
  height: 40px;
  width: 40px;
  top: 0;
  box-shadow: 0 0 0 5px hsla(0, 0%, 100%, 0.2);
  background-image: url("../../assets/img/Vector.svg");
  position: absolute;
  background-color: #ff8707;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text::before {
    left: -56px;
    height: 32px;
    width: 32px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text + .activity-slider-wrapper {
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text {
    margin-left: 72px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text p {
  color: #FFF;
  font-weight: normal;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text p:not(:last-child) {
  margin: 0 0 20px 0 0;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text p strong {
  font-weight: 900;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text ul {
  list-style-type: disc;
  padding-left: 20px;
  color: #fff;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text ul:not(:last-child) {
  margin: 0 0 20px 0;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text ul li {
  padding-left: 5px;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text ul li strong {
  font-weight: 700;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text.has-img img {
  margin-right: 12px;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper .ai-text.has-img .text-wrap a {
  font-weight: 700;
  color: #000000;
  line-height: 20px;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper.type-icon {
  background-color: #003451;
  padding: 5px 30px;
  border-radius: 12px 12px 12px 0px;
  margin-bottom: 12px;
  width: 100%;
  max-width: 52px;
  margin-top: 12px;
  margin-left: 40px;
  display: none;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper.type-icon {
    margin-left: 16px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper.type-icon .typing {
  position: relative;
  height: 20px;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper.type-icon .typing span {
  content: "";
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
  height: 8px;
  width: 8px;
  background: #FF8707;
  position: absolute;
  left: -15px;
  top: 12px;
  border-radius: 50%;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper.type-icon .typing span:nth-child(2) {
  animation-delay: 0.2s;
  margin-left: 12px;
}
.chatbot-view .wrapper .screen-chat .screen-sg .chat-list .list-wrapper.type-icon .typing span:nth-child(3) {
  animation-delay: 0.4s;
  margin-left: 24px;
}
@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .footer .text-field {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 24px;
  text-decoration: none;
  color: #000000;
  border-radius: 76px;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .footer .text-field {
    padding: 7px 16px 7px 24px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .footer .text-field input {
  font-family: "Abu Dhabi Basis";
  border: 0;
  width: 100%;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 767px) {
  .chatbot-view .wrapper .screen-chat .screen-sg .footer .text-field input {
    padding-left: 0px;
  }
}
.chatbot-view .wrapper .screen-chat .screen-sg .footer .text-field input:focus-visible {
  outline: none;
}
.chatbot-view .wrapper .screen-chat .screen-sg .footer .text-field button {
  border: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
}
.chatbot-view .wrapper .screen-chat .screen-sg .footer .text-field button img {
  height: 40px;
}
.chatbot-view .wrapper .screen-chat .screen-sg .footer .disclaimer {
  padding: 5px 0;
}

.chatbot-view .wrapper .screen-intro {
  padding-bottom: 48px;
}
@media (max-width: 990px) {
  .chatbot-view .wrapper .screen-intro {
    padding: 0;
    padding-bottom: 20px;
  }
}

*:focus {
  outline: none;
}

html {
  box-sizing: border-box;
}
html.SideBarActive {
  overflow: hidden;
}

body {
  display: unset;
}
body.SideBarActive {
  overflow: hidden;
  position: relative;
}
body.SideBarActive .header-wrapper::before {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  z-index: 3;
}
@media (max-width: 767px) {
  body.SideBarActive .header-wrapper::before {
    background: transparent;
  }
}

.section-image {
  display: none;
}
.section-image img {
  width: 100%;
}
@media (max-width: 767px) {
  .section-image {
    display: block;
  }
}

.header-wrapper {
  position: relative;
  background-color: #eee; /* Fallback color */
  background-image: url("https://cdn.pixabay.com/photo/2023/01/01/13/47/street-7690119_1280.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: calc(var(--vh-static, 1vh) * 100);
}
.header-wrapper.not-in-viewport .chat-assistant-btn {
  transition: ease 0.5s all;
}
@media (max-width: 767px) {
  .header-wrapper.not-in-viewport .chat-assistant-btn {
    left: auto;
    right: 20px;
    transform: rotate(0Deg);
    top: 45px;
    width: auto;
    background-color: transparent;
    box-shadow: none;
    visibility: visible;
    position: fixed;
    display: flex;
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .header-wrapper.not-in-viewport .chat-assistant-btn .assistant-icon {
    width: 57px;
    height: 57px;
    border-top-right-radius: 6px;
  }
}
@media (max-width: 767px) {
  .header-wrapper.not-in-viewport .chat-assistant-btn .assistant-icon img {
    width: 32px;
    height: auto;
  }
}
@media (max-width: 767px) {
  .header-wrapper.not-in-viewport .chat-assistant-btn .assistant-icon::after {
    display: none;
  }
}
@media (max-width: 767px) {
  .header-wrapper.not-in-viewport .chat-assistant-btn span {
    display: none;
  }
}
.header-wrapper .video-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.header-wrapper .video-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
}
@media (max-width: 767px) {
  .header-wrapper .video-box::before {
    background-color: #000000;
    position: absolute;
    height: 100%;
    width: 100vw;
    left: 0;
    top: 0;
    content: "";
    opacity: 0.2;
    z-index: 1;
  }
}
.header-wrapper .video-box video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header-wrapper .navbar {
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 5.8% 0 5.8%;
  width: 88.5%;
  padding: 27px 0 39px;
}
@media (max-width: 1023px) {
  .header-wrapper .navbar {
    width: calc(100% - 48px);
    margin: 0 24px 0 24px;
    height: 80px;
  }
}
.header-wrapper .navbar .nav-btns-desktop {
  display: flex;
}
.header-wrapper .navbar .nav-btns-desktop div img {
  width: clamp(30px, 3rem, 56px);
  height: clamp(30px, 3rem, 56px);
}
.header-wrapper .navbar .nav-btns-desktop div:not(:last-child) {
  margin-right: 0.75rem;
}
.header-wrapper .navbar .nav-btns-desktop div:last-child img {
  width: clamp(60px, 5rem, 80px);
}
@media (max-width: 1366px) {
  .header-wrapper .navbar .nav-btns-desktop img {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1023px) {
  .header-wrapper .navbar .nav-btns-desktop {
    display: none;
  }
}
.header-wrapper .navbar .nav-btns-mobile {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .header-wrapper .navbar .nav-btns-mobile div {
    margin-right: 10px;
  }
  .header-wrapper .navbar .nav-btns-mobile div:last-child {
    margin-right: 0px;
  }
}
.header-wrapper .navbar .nav-btns-mobile div img {
  width: 24px;
  height: 24px;
}
@media (min-width: 1023px) {
  .header-wrapper .navbar .nav-btns-mobile {
    display: none;
  }
}
.header-wrapper .navbar .nav-menu-wrapper {
  display: flex;
  align-items: center;
}
.header-wrapper .navbar .nav-menu-wrapper .logo {
  margin-right: 75px;
}
@media (max-width: 1199px) {
  .header-wrapper .navbar .nav-menu-wrapper .logo {
    margin-right: 60px;
  }
}
.header-wrapper .navbar .nav-menu-wrapper .logo img {
  width: 100%;
}
@media (max-width: 1023px) {
  .header-wrapper .navbar .nav-menu-wrapper .logo {
    margin-right: 24px;
  }
  .header-wrapper .navbar .nav-menu-wrapper .logo img {
    width: 113px;
  }
}
@media (max-width: 1023px) {
  .header-wrapper .navbar .nav-menu-wrapper .nav-links {
    display: none;
  }
}
.header-wrapper .navbar .nav-menu-wrapper .nav-links .nav-links-wrapper {
  display: flex;
}
.header-wrapper .navbar .nav-menu-wrapper .nav-links .nav-links-wrapper li {
  color: white;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 28.8px;
  margin-right: 36px;
  font-size: clamp(12px, 1rem, 22px);
}
@media (max-width: 1199px) {
  .header-wrapper .navbar .nav-menu-wrapper .nav-links .nav-links-wrapper li {
    font-size: 10px;
    margin-right: 15px;
  }
}
.header-wrapper .content-section {
  z-index: 2;
  position: relative;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 92px;
  width: 50%;
}
@media (max-width: 1023px) {
  .header-wrapper .content-section {
    margin: 0 24px;
    width: calc(100% - 300px);
  }
}
@media (max-width: 767px) {
  .header-wrapper .content-section {
    margin: 0 24px;
    width: calc(100% - 48px);
  }
}
.header-wrapper .content-section .title {
  font-weight: 700;
  font-size: 5.4rem;
  line-height: 1;
  margin-bottom: 30px;
}
@media (max-width: 1439px) {
  .header-wrapper .content-section .title {
    font-size: 6rem;
  }
}
@media (max-width: 1023px) {
  .header-wrapper .content-section .title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .header-wrapper .content-section .title {
    margin-bottom: 16px;
  }
}
.header-wrapper .content-section .description {
  font-weight: 400;
  font-size: 19.2px;
  line-height: 27.6px;
}
.header-wrapper .content-section .description strong {
  font-weight: 700;
}
@media (max-width: 767px) {
  .header-wrapper .content-section .description strong {
    font-weight: 400;
  }
}
@media (max-width: 767px) {
  .header-wrapper .content-section .description {
    margin-top: 0;
    font-size: 16px;
    line-height: 24px;
  }
}
.header-wrapper .content-section .chat-search-field {
  position: relative;
  margin-top: 28.8px;
  width: clamp(321px, 57%, 40rem);
}
@media (max-width: 767px) {
  .header-wrapper .content-section .chat-search-field {
    margin-top: 16px;
    width: 100%;
  }
}
.header-wrapper .content-section .chat-search-field .chat-field-input {
  font-family: "Abu Dhabi Basis";
  width: 100%;
  padding: 14.4px 24px;
  border-radius: 120px;
  font-weight: 700;
  color: #000;
  line-height: 19.2px;
  height: 68px;
  font-size: 17px;
}
.header-wrapper .content-section .chat-search-field .chat-field-input::placeholder {
  color: #000;
}
@media (max-width: 767px) {
  .header-wrapper .content-section .chat-search-field .chat-field-input {
    height: 67px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
.header-wrapper .content-section .chat-search-field .chat-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: #FF8707;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .header-wrapper .content-section .chat-search-field .chat-btn {
    width: 57px;
    height: 57px;
    right: 3px;
  }
}
.header-wrapper .content-section .chat-search-field .chat-btn img {
  width: 27px;
  height: 26px;
}
@media (max-width: 767px) {
  .header-wrapper .content-section .chat-search-field .chat-btn img {
    width: 32px;
    height: 100%;
  }
}
.header-wrapper .supergraphicImg {
  width: auto;
  height: calc(var(--vh-static, 1vh) * 83.1);
  bottom: 16px;
  right: -150px;
  position: absolute;
}
@media (max-width: 1023px) {
  .header-wrapper .supergraphicImg {
    right: -250px;
    bottom: -200px;
  }
}
@media (max-width: 400px) {
  .header-wrapper .supergraphicImg {
    right: -150px;
    bottom: -200px;
  }
}
.header-wrapper .supergraphicImg img {
  width: auto;
  height: 100%;
}
.header-wrapper .chat-assistant-btn {
  z-index: 2;
  position: absolute;
  display: flex;
  top: 50%;
  right: -190px;
  width: 223px;
  height: 66px;
  box-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.3);
  color: #fff;
  cursor: pointer;
  padding: 0 16px;
  align-items: center;
  background: #003451;
  justify-content: space-around;
  border-radius: 20px 20px 0 0;
  transform: translate(-50%, -50%) rotate(-90deg);
}
@media (max-width: 767px) {
  .header-wrapper .chat-assistant-btn {
    background: transparent;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    left: auto;
    top: unset;
    padding: 0;
    right: auto;
    width: 204px;
    height: 50px;
    transform: translate(0%, 0%) rotate(0);
  }
}
.header-wrapper .chat-assistant-btn .assistant-icon {
  position: relative;
  display: flex;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: orange;
}
@media (max-width: 767px) {
  .header-wrapper .chat-assistant-btn .assistant-icon {
    width: 26px;
    height: 26px;
  }
}
.header-wrapper .chat-assistant-btn .assistant-icon:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  width: 41px;
  height: 41px;
  border-radius: 50px;
  border: 5px solid rgba(255, 255, 255, 0.2);
}
@media (max-width: 767px) {
  .header-wrapper .chat-assistant-btn .assistant-icon:after {
    width: 36px;
    height: 36px;
  }
}
.header-wrapper .chat-assistant-btn .assistant-icon img {
  width: 15.5px;
  height: 14px;
  transform: rotate(90deg);
}
@media (max-width: 767px) {
  .header-wrapper .chat-assistant-btn .assistant-icon img {
    transform: rotate(0deg);
  }
}
.header-wrapper .chat-assistant-btn span {
  font-size: 14px;
  font-weight: 700;
}
.header-wrapper .chat-bot-app {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  max-width: 600px;
  height: 100%;
  background-color: #003451;
  transition: all 0.5s ease;
  transform: translateX(1080px);
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app {
    width: 100%;
    max-width: 100%;
    transform: translateX(0);
    transform: translateY(115%);
  }
}
.header-wrapper .chat-bot-app.active {
  z-index: 3;
  width: 600px;
  transform: translateX(0);
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app.active {
    position: fixed;
    width: 100%;
    transform: translateX(0);
    transform: translateY(0);
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper {
  width: 100%;
  height: 100%;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover {
  position: relative;
  height: 100%;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .screen-close-btn {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .screen-close-btn {
    display: none;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .screen-close-btn a {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 16px;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  background-color: #fff;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-bg {
  bottom: 0;
  height: auto;
}
@media (max-height: 750px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-bg {
    height: 80%;
  }
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-bg img {
    object-fit: fill;
  }
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-bg {
    height: auto;
  }
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-bg img {
    object-fit: cover;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg {
  padding: 0;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .header {
  background-color: #003451;
  padding: 26px 0;
  text-align: center;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .header a {
  display: none;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .header {
    position: relative;
    padding: 24px 0;
    padding-left: 16px;
  }
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .header a {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .header p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.96px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .header p {
    font-size: 16px;
    line-height: 24px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper {
  height: 100%;
  padding: 0 40px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper {
    padding: 0 16px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-text {
  padding-bottom: 80px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-text {
    padding-bottom: 32px;
  }
}
@media (max-height: 900px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-text {
    padding-bottom: 40px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-text h2 {
  font-size: 64px;
  color: #ffffff;
  line-height: 72px;
  font-weight: 700;
}
@media (max-width: 1440px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-text h2 {
    font-size: 48px;
    line-height: 56px;
  }
}
@media (max-height: 900px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-text h2 {
    font-size: 40px;
    line-height: 48px;
  }
}
@media (max-width: 991px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-text h2 {
    font-size: 42px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-text h2 {
    font-size: 40px;
    line-height: 48px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-content {
  padding: 0;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-content .help-txt p {
  font-size: 24px;
  color: #ffffff;
  font-weight: 400;
  line-height: 31.2px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-content .help-txt p {
    display: none;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-content .chat-topics {
  margin-top: 32px;
  max-height: 272px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-content .chat-topics {
    max-height: 276px;
    overflow: scroll !important;
    width: calc(100% + 10px);
    padding-right: 10px;
  }
}
@media (max-height: 750px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .content-wrapper .topic-content .chat-topics {
    max-height: 180px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .footer {
  width: 100%;
  padding: 0 40px;
  margin-top: 80px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .footer {
    padding: 0 16px;
    margin-top: 20px;
    padding-bottom: 16px;
  }
}
@media (max-height: 900px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .footer {
    margin-top: 40px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .footer .footer-words-count {
  display: flex;
  margin-top: 22px;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .footer .footer-words-count {
    margin-top: 16px;
    display: none;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .footer .footer-words-count p, .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .footer .footer-words-count span {
  font-size: 12px;
  color: #898C8D;
  font-weight: 400;
  line-height: 14.64px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .footer .footer-words-count p, .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-intro .screen-fg .footer .footer-words-count span {
    font-size: 12px;
    line-height: 13px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat {
  position: relative;
  padding: 0;
  height: 100%;
  padding-bottom: 48px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat {
    padding-bottom: 0;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-bg img {
  width: 100%;
  height: 100%;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper {
    height: 100%;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .header {
  padding: 26px 40px;
  text-align: center;
  background: #003451;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .header {
    padding: 24px 0;
    padding-left: 16px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .header .js-cta-end {
  display: none;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .header .js-cta-end {
    display: block;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .header p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.96px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .header p {
    font-size: 16px;
    line-height: 24px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .content-wrapper {
  height: auto;
  overflow: auto;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .content-wrapper {
    height: 100% !important;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .content-wrapper .chat-list {
  padding: 0px;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper {
  width: 100%;
  padding: 0 0px 20px 40px;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper {
    width: calc(100% + 16px);
    padding: 0;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider {
  width: 100%;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box {
  overflow: visible;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box {
    padding: 0 16px;
    overflow: scroll;
    display: flex;
    padding-bottom: 10px !important;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slick-list {
  width: 100%;
  overflow: visible;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slick-list .slick-track {
  display: flex;
  justify-content: flex-start;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item {
  border: 3px solid rgba(255, 255, 255, 0.1);
  width: 260px !important;
  margin-right: 16px;
  border-radius: 16px;
  background: #003451;
  box-shadow: 0px 5.217px 83.478px 0px rgba(41, 38, 47, 0.1);
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item {
    width: 200px !important;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-img {
  position: relative;
  height: 136px;
  overflow: hidden;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-img img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 16px 16px 0 0;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-img .label {
  position: absolute;
  top: 14px;
  left: 14px;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-img .label span {
  display: flex;
  font-size: 12px;
  font-weight: 700;
  color: #000000;
  padding: 5px 10px;
  line-height: 20px;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-img .label span {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-content {
  padding: 18px 18px 20px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-content {
    padding: 14px 15px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-content h2 {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  line-height: 31px;
  margin-bottom: 13px;
  min-height: 64px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  line-height: 31px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-content h2 {
    font-size: 18px;
    margin-bottom: 9px;
    line-height: 23px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-content p {
  font-size: 14px;
  font-weight: 400;
  color: #898C8D;
  line-height: 18px;
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds an ellipsis at the end of the line */
  width: 100%; /* Set the width of the container */
  display: block;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slider-item .item-content p {
    width: 170px;
    font-size: 12px;
    line-height: 15px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slick-arrow {
  position: absolute;
  bottom: -60px;
  right: 12px;
  text-indent: -9999px;
  width: 46px;
  height: 46px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slick-arrow {
    width: 30px;
    height: 30px;
    right: 20px;
    display: none !important;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slick-arrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  background-image: url("../../assets/img/arrow-black.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slick-arrow.slick-prev {
  right: 66px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slick-arrow.slick-prev {
    left: 0px;
    display: none;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slick-arrow.slick-prev:before {
  transform: translate(-50%, -50%) rotate(180deg);
  background-image: url("../../assets/img/arrow-black.svg");
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .activity-slider-wrapper .activity-slider .slider-box .slick-arrow.slick-disabled {
  opacity: 0.2;
  pointer-events: none;
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer {
  bottom: 0;
  width: 100%;
  padding: 5px 40px 0 40px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer {
    position: absolute;
    background-color: #003C57;
    padding: 5px 16px 0px 16px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer .footer-words-count {
  display: flex;
  margin-top: 22px;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer .footer-words-count {
    margin-top: 16px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer .footer-words-count p,
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer .footer-words-count .char-count {
  font-size: 12px;
  color: #898C8D;
  font-weight: 400;
  line-height: 14.64px;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer .footer-words-count p,
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer .footer-words-count .char-count {
    font-size: 9px;
    line-height: 13px;
  }
}
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer .footer-words-count p.limit-exceeded,
.header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer .footer-words-count .char-count.limit-exceeded {
  color: red;
}
@media (max-width: 767px) {
  .header-wrapper .chat-bot-app .chat-bot-app-wrapper .screen-cover .chatbot-view .wrapper .screen-chat .screen-sg .chat-screen-wrapper .footer .footer-words-count .char-count {
    display: none;
  }
}

.js-chat-header {
  height: 75px;
}

.js-chat-footer {
  height: 153px;
}
@media (max-width: 767px) {
  .js-chat-footer {
    height: 103px;
  }
}

@media (max-width: 767px) {
  .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
  .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
  .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background: #ffffff;
  }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 3px;
    background: #ffffff;
  }
  .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
    opacity: 1;
    width: 1px;
    height: 100%;
  }
  .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
    border-radius: 24px;
    background: rgba(247, 247, 247, 0.5);
  }
  .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical,
  .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
    top: -10px;
    right: -10px;
  }
}
.ctaWrapper {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #0D746D;
  border-radius: 40px;
  padding: 14px 16px;
  background-color: #009086;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  pointer-events: none;
}
@media (max-width: 767px) {
  .ctaWrapper {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}
.ctaWrapper a {
  margin-right: 32px;
}
.ctaWrapper a:last-child {
  position: relative;
  margin-right: 0;
}
.ctaWrapper a:last-child::before {
  width: 1px;
  height: 24px;
  left: -16px;
  top: 0;
  background-color: #0D746D;
  content: "";
  position: absolute;
}