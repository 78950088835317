html{
    margin: 0;
    font-family:'Abu Dhabi Basis';
    font-size: .833333333vw;
    font-weight: 400;
    overflow: hidden;
    color: #000000;
    @media (max-width: 767px) {
        overflow: auto;
        font-size: 3.833333333vw;
    }
}
body {
    overflow: hidden;
    margin: 0;
    font-family:'Abu Dhabi Basis';
    font-size: .833333333vw;
    font-weight: 400;
    color: #000000;
    @media (max-width: 767px) {
        overflow: auto;
        font-size: 3.833333333vw;
    }
}
h1 {
    font-size: 48px;
    line-height: 1.25;
    font-weight: 700;
}
p {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;
}
.para-large {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    strong {
        font-weight: 700;
    }
}
.para-small {
    font-size: 8px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
}

// remove ios custom styles from input fields
input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // and borders aborder radius as well
    border-radius: 0;
    border: 0;
    &:focus-visible {
        outline: none;
    }
}

@media (min-width: 991px) {
    body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    .screen-cover {
        position: relative;
        // padding: 20px;
        width: 100%;
        height: 100%;
        .mobile-frame {
            // width: 423px;
            width: 100%;
            margin-left: auto;
            // background: url(../img/mobileframe.png) 0 0;
            // padding: 20px 0;
            height: 100%;
        }
    }
    .chatbot-view {
        position: relative;
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            z-index: 20;
            // background: url(../img/browsertabs.jpg) 0 bottom no-repeat, url(../img/statusbar.jpg) 0 0 no-repeat;
            pointer-events: none;
        }
    }
}


.chatbot-view {
    width: 100%;
    // max-width: 375px;
    margin: 0 auto;
    // border-radius: 45px;
    overflow: hidden;
    height: 100%;
    @media (max-width: 990px) {
        height: 100vh;
        border-radius: 0;
        max-width: none;
    }
    .wrapper {
        position: relative;
        height: 100%;
        .screen-close-btn{
            position: absolute;
            left: -50px;
            top: 50px;
        }
        .screen-intro {
            position: relative;
            height: 100%;
            .screen-bg {
                position: absolute;
                z-index: 0;
                width: 100%;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .screen-fg {
                position: relative;
                padding: 30px 0 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                @media (max-width: 990px) {
                    padding: 40px 0 10px;
                    max-width: 375px;
                    margin: 0 auto;
                }
                @media (max-width: 990px) {
                    max-width: 100%;
                }
                .header {
                    padding: 0 16px;
                    flex: 0 0 auto;
                    h1 {
                        font-size: 42px;
                        line-height: 1.2;
                        i {
                            white-space: nowrap;
                        }
                        span {
                            color: #FF8707;
                        }
                    }
                }
                .topic-content {
                    padding: 35px 16px;
                    overflow: hidden;
                    @media (max-width: 767px) {
                        flex: 1 1 auto;
                        overflow: visible;
                    }
                    .chat-topics {
                        height: 100%;
                        overflow: scroll;
                        overflow-x: hidden;
                        .topic-wrapper {
                            a {
                                display: flex;
                                align-items: center;
                                padding: 8px;
                                text-decoration: none;
                                margin-bottom: 4px;
                                color: #000;
                                font-weight: 400;
                                line-height: 24px; /* 150% */
                                background-color: #fff;
                                backdrop-filter: blur(16px);
                                border-radius: 24px 24px 4px 24px;
                                @media (max-width: 767px) {
                                    padding: 8px;
                                }
                                img {
                                    max-width: 72px;
                                    margin-right: 16px;
                                    border-radius: 16px;
                                    height: 72px;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                p{
                                    font-size: 20px;
                                    font-weight: 400;
                                    line-height: 24px;
                                    @media (max-width: 767px) {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
                .footer {
                    padding: 6px 16px;
                    flex: 0 0 auto;
                    .cta-start {
                        background-color: #fff;
                        border-radius: 76px;
                        a {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 3px 4px 0 24px;
                            text-decoration: none;
                            color: #000000;
                            cursor: text;
                            border: 4px solid rgba(255, 255, 255, 0.20);
                            box-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.2);
                            border-radius: 76px;
                            span.text {
                                font-size: 16px;
                                display: block;
                                position: relative;
                                top: -1px;
                            }
                        }
                    }
                }
            }
        }
        .screen-chat {
            display: none;
            padding-top: 120px;
            height: 100%;
            .screen-sg {
                position: relative;
                height: 100%;
                display: flex;
                flex-direction: column;
                .header {
                    flex: 0 0 auto;
                    padding: 15px 16px;
                    display: flex;
                    align-items: center;
                    box-shadow: 0 5px 8px 0 rgba(0,0,0,.06);
                    .para-large {
                        flex: 0 0 92%;
                        max-width: 92%;
                        margin-left: 4px;
                        @media (max-width: 767px) {
                            flex: 0 0 85%;
                            max-width: 85%;
                        }
                    }
                }
                .chat-member-wrapper{
                    display: flex;
                    margin-top: 5px;
                    margin-left: 5px;
                    margin-bottom: 40px;
                    @media (max-width: 767px) {
                        margin-bottom: 32px;
                    }
                    .chat-member{
                        position: relative;
                        display: flex;
                        width: 40px;
                        height: 40px;
                        margin-right: 20px;
                        align-items: center;
                        border-radius: 50px;
                        background: #FF8707;
                        justify-content: center;
                        @media (max-width: 767px) {
                            width: 32px;
                            height: 32px;
                        }
                        img{
                            height: 18px;
                        }
                        &:after{
                            content: '';
                            position: absolute;
                            top: -5px;
                            left: -5px;
                            right: -5px;
                            bottom: -5px;
                            width: 50px;
                            height: 50px;
                            border-radius: 50px;
                            border: 5px solid rgba(255, 255, 255, 0.20);
                            @media (max-width: 767px) {
                                width: 42px;
                                height: 42px;
                            }
                        }
                    }
                    .default-text {
                        color: #ffffff;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 31px;
                        @media (max-width: 767px) {
                            font-size: 18px;
                            line-height: 23px;
                        }
                    }
                }
                .chat-list {
                    padding: 0 16px;
                    flex: 1 1 auto;
                    overflow: hidden;
                    // border-bottom: 1px solid #eeeeee;
                    .chat-wrapper {
                        height: 100%;
                        // max-height: 758px;
                        // overflow: scroll;
                        overflow-x: hidden;
                        @media (max-width: 767px) {
                            max-height: calc(100svh - 101px - 75px);
                        }
                    }
                    .suggestions-list {
                        padding: 0 40px;
                        padding-top: 35px;
                        @media (max-width: 767px) {
                            padding: 0 16px;
                        }
                        .list-wrapper {
                            display: flex;
                            flex-wrap: wrap;
                            margin-bottom: 60px;
                            justify-content: flex-end;
                            @media (max-width: 767px) {
                                margin-bottom: 40px;
                            }
                            a {
                                color: #fff;
                                padding: 12px 16px;
                                border-radius: 12px;
                                margin: 0 0 6px 6px;
                                background: #003451;
                                text-decoration: none;
                                border: 3px solid rgba(255, 255, 255, 0.1);
                                p{
                                    font-size: 18px;
                                    font-weight: 400;
                                    line-height: 24px;
                                    @media (max-width: 767px) {
                                        font-size: 16px;
                                        line-height: 24px;
                                    }
                                }
                            }
                        }
                    }

                    .list-wrapper {
                        margin-top: 40px;
                        &.js-msg-list{
                            padding-right: 40px;
                            font-size: 16px;
                            @media (max-width: 767px) {
                                padding-right: 16px;
                            }    
                        }
                        @media (max-width: 767px) {
                            padding-right: 0;
                        }
                        li {
                            margin-bottom: 24px;
                            max-width: fit-content;
                            @media (max-width: 767px) {
                                margin-bottom: 16px;
                            }
                        }
                        .user-text {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 20px;
                            margin-right: 0;
                            color: #000000;
                            margin-left: auto;
                            padding: 16px 20px;
                            width: calc(100% - 16px);
                            background-color: #ffffff;
                            border-radius: 24px 24px 0px 24px;
                            word-break: break-word;
                            overflow-wrap: break-word;
                            @media (max-width: 767px) {
                                margin-bottom: 16px;
                            }
                        }
                        .ai-text {
                            background-color: #003451;
                            padding: 12px;
                            border-radius: 0px 12px 12px 12px;
                            margin-bottom: 24px;
                            width: max-content;
                            margin-left: 100px;
                            min-height: 53px;
                            position: relative;
                            @media (max-width: 767px) {
                                margin-bottom: 16px;
                            }
                            &.empty{
                                background-color: transparent;
                            }
                            &::before{
                                border-radius: 50%;
                                left: -60px;
                                height: 40px;
                                width: 40px;
                                top: 0;
                                box-shadow: 0 0 0 5px hsla(0, 0%, 100%, 0.2);
                                background-image: url('../../assets/img/Vector.svg');
                                position: absolute;
                                background-color: #ff8707;
                                content: '';
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: 50%;
                                @media (max-width: 767px) {
                                    left: -56px;
                                    height: 32px;
                                    width: 32px;
                                }
                            }
                            + .activity-slider-wrapper{
                                margin-bottom: 12px;
                            }
                            @media (max-width: 767px) {
                                margin-left: 72px;
                            }
                            p {
                                color: #FFF;
                                font-weight: normal;
                                &:not(:last-child) {
                                    margin: 0 0 20px 0 0;
                                }

                                strong {
                                    font-weight: 900;
                                }
                            }
                            ul {
                                list-style-type: disc;
                                padding-left: 20px;
                                color: #fff;
                                &:not(:last-child) {
                                    margin: 0 0 20px 0;
                                }
                                li {
                                    padding-left: 5px;
                                    strong{
                                        font-weight: 700;
                                    }
                                }
                            }
                            &.has-img {
                                img {
                                    margin-right: 12px;
                                }
                                .text-wrap {
                                    a {
                                        font-weight: 700;
                                        color: #000000;
                                        line-height: 20px;
                                    }
                                }
                            }
                        }
                        &.type-icon {
                            background-color: #003451;
                            padding: 5px 30px;
                            border-radius: 12px 12px 12px 0px;
                            margin-bottom: 12px;
                            width: 100%;
                            max-width: 52px;
                            margin-top: 12px;
                            margin-left: 40px;
                            display: none;
                            @media (max-width: 767px) {
                                margin-left: 16px;
                            }
                            .typing {
                                position: relative;
                                height: 20px;
                                
                                span {
                                  content: '';
                                  animation: blink 1.5s infinite;
                                  animation-fill-mode: both;
                                  height: 8px;
                                  width: 8px;
                                  background: #FF8707;
                                  position: absolute;
                                  left: -15px;
                                  top: 12px;
                                  border-radius: 50%;
                                  
                                  &:nth-child(2) {
                                    animation-delay: .2s;
                                    margin-left: 12px;
                                  }
                                  
                                  &:nth-child(3) {
                                    animation-delay: .4s;
                                    margin-left: 24px;
                                  }
                                }
                              }
                              
                              
                              @keyframes blink {
                                0% {
                                  opacity: .1;
                                }
                                20% {
                                  opacity: 1;
                                }
                                100% {
                                  opacity: .1;
                                }
                              }
                        }
                    }
                }
                .footer {
                    .text-field {
                        background-color: #fff;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 8px 16px 8px 24px;
                        text-decoration: none;
                        color: #000000;
                        // border-bottom: 1px solid #eee;
                        border-radius: 76px;
                        box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.20);
                        @media (max-width: 767px) {
                            padding: 7px 16px 7px 24px;
                        }
                        input {
                            font-family:'Abu Dhabi Basis';
                            border: 0;
                            width: 100%;
                            font-size: 16px;
                            color: #000000;
                            font-weight: 400;
                            line-height: 24px;
                            @media (max-width: 767px) {
                                padding-left: 0px;
                            }
                            &:focus-visible {
                                outline: none;
                            }
                        }
                        button {
                            border: 0;
                            width: 40px;
                            height: 40px;
                            cursor: pointer;
                            // margin-left: 15px;
                            padding: 0;
                            // padding-right: 16px;
                            background-color: transparent;
                            img {
                                height: 40px;
                            }
                        }
                    }
                    .disclaimer {
                        padding: 5px 0;
                    }
                }
            }
        }
    }
}

.chatbot-view .wrapper .screen-intro{
    // // padding-top: 36px;
    padding-bottom: 48px;
    @media (max-width: 990px) {
        padding: 0;
        padding-bottom: 20px;
    }
}
@media (max-width: 990px) {}

// remove outline when focused on elements
*:focus {
    outline: none;
}
// $dot-width: 10px;
// $dot-color: #FF8707;
// $speed: 1.5s;

