@font-face {
    font-family:'Abu Dhabi Basis';
    src:url("../fonts/BasicAbuDhabi-Regular.woff2") format("woff2"),
        url("../fonts/BasicAbuDhabi-Regular.woff") format("woff");
    font-style:normal;
    font-weight:400;
}
@font-face {
    font-family:'Abu Dhabi Basis';
    src:url("../fonts/BasicAbuDhabi-Bold.woff2") format("woff2"),
        url("../fonts/BasicAbuDhabi-Bold.woff") format("woff");
    font-style:normal;
    font-weight:700;
}